<template>
    <b-row>
        <b-col lg="12" sm="12">
            <b-overlay :show="loader">
                <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
                    <template v-slot:projectNameSlot>
                    {{ }}
                    </template>
                    {{ $t('globalTrans.refund') }}
                </list-report-head>
                <b-row>
                    <b-table-simple v-if="circular_type === 1 && stalls.length" bordered small>
                        <thead>
                            <tr>
                                <b-th colspan="7" class="text-white text-center p-2 bg-dark">{{ $t('tradeFairConfig.stall_information') }}</b-th>
                            </tr>
                            <tr class="bg-primary text-center text-align-center">
                                <th style="width:5%">{{ $t('globalTrans.select') }}</th>
                                <th style="width:5%">{{ $t('globalTrans.sl_no') }}</th>
                                <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th style="width:15%">{{ $t('ditfConfig.stall_no') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.quoted_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.security_money') }}</th>
                            </tr>
                        </thead>
                        <b-tbody>
                            <!-- <pre>dsf{{ stalls }}</pre> -->
                            <span hidden>{{ $si = 1 }}</span>
                            <template v-for="(items, index) in stalls">
                                <b-tr v-for="(item, index1) in items.details" :key="index+'key'+index1">
                                    <!-- select category wise payment refund -->
                                    <b-td v-if="index1 === 0" class="align-middle text-center" :rowspan="items.details.length">
                                        <b-form-checkbox
                                        style="display: table-cell"
                                        class="col-form-label-sm font-weight-bold mb-0"
                                        name="checked"
                                        v-model="items.isSelected"
                                        :onClick="getTotalSMoney(stalls)"
                                        :value=true
                                        :unchecked-value=false
                                        >
                                        </b-form-checkbox>
                                    </b-td>
                                    <b-td v-if="index1 === 0" class="align-middle text-center" :rowspan="items.details.length">{{ $n($si++) }}</b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.details.length" class="align-middle text-center">{{ getStallCategoryName(items.stall_cat_id) }}</b-td>
                                    <b-td class="text-center">{{ item.stall_info.stall_no }} <br>
                                         <span v-if="item.stall_info.status === 2" class="badge text-bg-success">Booked</span>
                                         <span class="badge text-bg-warning" v-else>Unbooked</span>
                                        </b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.details.length" class="align-middle text-center">{{ $n(getFloorPrice(items.stall_cat_id), { minimumFractionDigits: 2 }) }}</b-td>
                                    <b-td class="text-center">{{ $n(item.quoted_price, { minimumFractionDigits: 2 }) }}</b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.details.length" class="align-middle text-center">{{ $n(getSecurityMoney(items.details[0]['quoted_price']), { minimumFractionDigits: 2 }) }}</b-td>
                                </b-tr>
                            </template>
                            <b-tr>
                                <b-td colspan="5" class="text-right">{{ $t('ditfAppTradeFairManages.cutting_amount') }}</b-td>
                                <b-td colspan="2" class="text-center">
                                    <ValidationProvider name="Cutting Amount" vid="cutting_amount" :rules="`max_value:${refund.total_refund}`" v-slot="{ errors }">
                                        <b-form-group label-for="cutting_amount" label-cols-sm="12">
                                            <b-form-input
                                            v-model="refund.cutting_amount"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="5" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                <b-td colspan="2" class="text-center">{{ $n(parseFloat(refund.total_refund - refund.cutting_amount) , { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-table-simple v-if="circular_type === 2" bordered small>
                        <thead>
                            <tr>
                                <b-th colspan="5" class="text-white text-center p-2 bg-dark">{{ $t('tradeFairConfig.stall_information') }}</b-th>
                            </tr>
                            <tr class="bg-primary text-center text-align-center">
                                <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                                <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.quoted_price') }}</th>
                            </tr>
                        </thead>
                        <b-tbody>
                            <span hidden>{{ $si = 1 }}</span>
                            <b-tr v-for="(item, index2) in app_info.stall_other.filter(el => el.status === 1)" :key="index2">
                                <b-td class="align-middle text-center">{{ $n($si++) }}</b-td>
                                <b-td class="align-middle text-center">{{ getStallCategoryName(item.stall_cat_id) }}</b-td>
                                <b-td class="align-middle text-center">{{ $n(getFloorPrice(item.stall_cat_id)) }}</b-td>
                                <b-td class="text-center">{{ $n(item.quoted_price) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="3" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                <b-td class="text-center">{{ $n(getQuotedPrice(app_info.stall_other)) }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-row>
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                        <b-row v-if="!view_mode">
                            <b-col xs="12" sm="12" md="4" lg="4" xl="6">
                                <ValidationProvider name="Refund Date" vid="refund_date" rules="required" v-slot="{ errors }">
                                    <b-form-group
                                        label-for="refund_date"
                                    >
                                        <template v-slot:label>
                                        {{ $t('globalTrans.date') }}  <span class="text-danger">*</span>
                                        </template>
                                        <date-picker
                                        id="refund_date"
                                        class="form-control"
                                        v-model="refund.refund_date"
                                        :class="errors[0] ? 'is-invalid' : ''"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        :placeholder="$t('globalTrans.select')"
                                        :config="{ static: true }"
                                        >
                                        </date-picker>
                                        <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="12" md="4" lg="4" xl="6">
                                <ValidationProvider name="Comments" vid="comments" rules="required" v-slot="{ errors }">
                                    <b-form-group
                                        label-for="comments">
                                        <template v-slot:label>
                                        {{ $t('globalTrans.comments') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-textarea
                                        id="comments"
                                        v-model="refund.comments"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                                <b-button v-if="!view_mode" type="button" @click="finalSave" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.refund') }}</b-button>
                                <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-refund')">{{ $t('globalTrans.cancel') }}</b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>
                <!-- <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                        <b-button v-if="!view_mode" type="button" @click="finalSave" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.refund') }}</b-button>
                        <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-refund')">{{ $t('globalTrans.cancel') }}</b-button>
                    </b-col>
                </b-row> -->
            </b-overlay>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { DitfRefundStore } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
    name: 'Form',
    props: ['id', 'circular_type', 'view_mode'],
    components: {
        ListReportHead
    },
    data () {
        return {
            baseUrl: internationalTradeFairServiceBaseUrl,
            loader: false,
            refundBooked: false,
            valid: null,
            app_info: {},
            stalls: [],
            selectAll: [],
            refund: {
                app_main_id: 0,
                cutting_amount: 0,
                total_refund: 0,
                refund_date: 0,
                comments: ''
            }
        }
    },
    created () {
        if (this.id) {
            this.app_info = this.getRefundInfo()
            if (this.app_info.circular_type === 1) {
                this.mappingStallInfo(this.app_info.stall_pavilion)
            }
            this.refund.app_main_id = this.id
            if (this.app_info.circular_type === 1) {
                // this.refund.total_refund = this.getTotalSMoney(this.stalls)
            } else {
                // this.refund.total_refund = this.getQuotedPrice(this.app_info.stall_other)
            }
        }
    },
    watch: {
    },
    methods: {
        mappingStallInfo (data) {
            const details = []
            // const booked = data.find(el => el.status === 2)
            const refunded = data.find(el => el.is_refund === 1)
            let stallArr = data
            // if (typeof booked !== 'undefined') {
            //     stallArr = data.filter(mItem => mItem.stall_cat_id !== booked.stall_cat_id && mItem.status === 1)
            // } else {
            //     stallArr = data.filter(mItem => mItem.status === 1)
            // }
            if (typeof refunded !== 'undefined') {
                stallArr = data.filter(mItem => mItem.stall_cat_id !== refunded.stall_cat_id && mItem.is_refund === 0)
            } else {
                stallArr = data.filter(mItem => mItem.is_refund === 0)
            }
            stallArr.forEach(item => {
                const Obj = details.find(el => el.stall_cat_id === item.stall_cat_id)
                if (typeof Obj === 'undefined') {
                    details.push({ stall_cat_id: item.stall_cat_id })
                }
            })
            const tA = details.map(el => {
                const newArry = data.filter(item => item.stall_cat_id === el.stall_cat_id)
                return Object.assign({}, el, { details: newArry })
            })
            this.stalls = tA
        },
        getFloorPrice (id) {
            const Obj = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            return Obj !== undefined ? Obj.floor_price : ''
        },
        getStallCategoryName (id) {
            const Obj = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            if (this.$i18n.locale === 'bn') {
            return Obj !== undefined ? Obj.text_bn : ''
            } else {
            return Obj !== undefined ? Obj.text_en : ''
            }
        },
        getSecurityMoney (data) {
            const Obj = this.$store.state.TradeFairService.ditfCommonObj.securityMoneyList.find(item => item.year === this.app_info.year)
            const percentage = Obj !== undefined ? Obj.percentage : 0
            return (percentage * data) / 100
        },
        getQuotedPrice (items) {
            if (items) {
                let quotedPrice = 0
                items.filter(el => el.status === 1).map((item) => {
                    quotedPrice += parseInt(item.quoted_price)
                })
                return quotedPrice
            }
        },
        getTotalSMoney (items) {
            if (items) {
                let sMoney = 0
                const Obj = this.$store.state.TradeFairService.ditfCommonObj.securityMoneyList.find(item => item.year === this.app_info.year)
                const percentage = Obj !== undefined ? Obj.percentage : 0
                items.map(el => {
                    if (el.isSelected) {
                        sMoney += (el.details[0].quoted_price * percentage) / 100
                        // const book = el.details.find(detail => detail.status === 2)
                        // if (book !== undefined) {
                        //     this.refundBooked = true
                        // } else {
                        //     this.refundBooked = false
                        // }
                    }
                })
                if (this.app_info.circular_type === 1) {
                    this.refund.total_refund = sMoney
                } else {
                    this.refund.total_refund = this.getQuotedPrice(this.app_info.stall_other)
                }
                return sMoney
            } else {
                return 0
            }
        },
        getRefundInfo () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async finalSave () {
            const selectedItem = this.stalls.filter(item => item.isSelected)
            if (selectedItem.length > 0) {
                var check = await this.$refs.form.validate()
                if (check) {
                    this.$swal({
                    title: this.$t('globalTrans.surePrompt'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('globalTrans.yes'),
                    cancelButtonText: this.$t('globalTrans.no'),
                    focusConfirm: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const stallCatIds = selectedItem.map(item => item.stall_cat_id)
                            this.saveUpdate(stallCatIds)
                        }
                    })
                }
            } else {
                this.$toast.warn({
                    title: this.$i18n.locale === 'en' ? 'Warning..!' : 'সতর্কতা..!',
                    message: this.$i18n.locale === 'en' ? 'At least one category needs to be selected' : 'অন্তত একটি ক্যাটাগরি সিলেক্ট করতে হবে।',
                    color: '#F5A623',
                    timeOut: 3000
                })
            }
        },
        async saveUpdate (stallCatIds) {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            this.loader = true
            const loadingState = { loading: false, listReload: false }
            const myObj = {
                app_main_id: this.id,
                stall_cat_ids: stallCatIds,
                refund_amount: this.refund.total_refund,
                cutting_amount: this.refund.cutting_amount,
                total_refund: parseFloat(this.refund.total_refund - this.refund.cutting_amount),
                refund_date: this.refund.refund_date,
                comments: this.refund.comments
            }
            if (this.id) {
                result = await RestApi.postData(internationalTradeFairServiceBaseUrl, DitfRefundStore, myObj)
            }
            this.loader = false
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            if (result.success) {
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-refund')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
